import MainLayout from '@/layouts/MainLayout';
import AppConfig from '@/config/app';

export default {
  name: 'TermsOfService',

  components: { MainLayout },

  data() {
    return {
      appName: AppConfig.NAME,
      appEmail: AppConfig.MAIN_EMAIL,
      appAuthor: AppConfig.AUTHOR,
      lastDateUpdated: '2023-12-01',
    };
  },
};
